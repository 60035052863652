@font-face {
  font-family: 'SourceSansPro-Black';

  src: local('SourceSansPro-Black'),
    url(./assets/fonts/SourceSansPro-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'SourceSansPro-BlackItalic';

  src: local('SourceSansPro-BlackItalic'),
    url(./assets/fonts/SourceSansPro-BlackItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'SourceSansPro-Bold';

  src: local('SourceSansPro-Bold'),
    url(./assets/fonts/SourceSansPro-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'SourceSansPro-Regular';

  src: local('SourceSansPro-Regular'),
    url(./assets/fonts/SourceSansPro-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'SourceSansPro-SemiBold';

  src: local('SourceSansPro-SemiBold'),
    url(./assets/fonts/SourceSansPro-SemiBold.ttf) format('truetype');
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  font-family: 'SourceSansPro-Regular', 'Open Sans', sans-serif;
}

#tiff-inner-container {
  display: flex;
  justify-content: center;
}
.tiff-viewer canvas {
  width: 70%;
  height: auto;
  max-height: 100%;
  background-color: rgb(35, 33, 33);
}
